/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback } from 'react';
import trackAddToCartAmp from '@utils/amplitude/track/add-to-cart';
import trackAddToCartGa from '@utils/gtm/track/add-to-cart';
import { useHistory } from '@utils/history';
import { HookFetcher } from '../utils/types';
import useCartRemoveItem from '../commerce/cart/use-remove-item';
import type { RemoveItemBody } from '../api/cart';
import useCart, { ShopifyCart } from './use-cart';

const defaultOpts = {
  url: '/api/maxify/cart',
  method: 'DELETE',
};

export type RemoveItemInput = {
  id: string | string[];
};

export const fetcher: HookFetcher<ShopifyCart | null, RemoveItemBody> = (
  options,
  { itemId },
  fetch
) =>
  fetch({
    ...defaultOpts,
    ...options,
    body: { itemId },
  });

export function extendHook(customFetcher: typeof fetcher) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useRemoveItem = () => {
    const { last: lastPage } = useHistory();
    const { data: lastData, mutate } = useCart();
    const fn = useCartRemoveItem<ShopifyCart | null, RemoveItemBody>(
      defaultOpts,
      customFetcher
    );

    return useCallback(
      async function removeItem(input: RemoveItemInput) {
        const data = await fn({ itemId: input.id });
        await mutate(data, false);

        trackAddToCartAmp({
          cart: data,
          referrer: lastPage,
          remove: true,
          removeId: input.id,
          lastCart: lastData,
        });

        trackAddToCartGa({
          cart: data,
          remove: true,
          removeId: input.id,
          lastCart: lastData,
        });

        return data;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fn, mutate]
    );
  };

  useRemoveItem.extend = extendHook;

  return useRemoveItem;
}

export default extendHook(fetcher);
