import { classnames } from '@tailwindcss-classnames';

export const bannerWrapper = classnames(
  'relative',
  'transition-colors',
  'duration-500'
);

const spacing = classnames('px-1', 'md:px-4', 'lg:px-12');
export const contentWrapper = classnames(
  'flex',
  'items-center',
  'justify-center',
  'h-full',
  'w-full',
  'my-auto'
);

export const outerWrapper = classnames(contentWrapper, 'overflow-hidden');
export const flexContainer = classnames(
  contentWrapper,
  spacing,
  'flex-col',
  'overflow-hidden',
  'h-global-banner'
);
export const flexContainerCarousel = classnames(
  'flex',
  'items-center',
  'max-w-screen-lg',
  'relative',
  'm-auto',
  'w-full',
  spacing,
  'flex-col'
);

export const carouselWrapper = (isMounted: boolean): string =>
  classnames('flex', 'h-full', 'items-center', 'w-full', {
    'opacity-0': !isMounted,
    'opacity-100': isMounted,
  });

export const textWrapper = classnames(
  'flex',
  'items-center',
  'justify-center',
  'space-x-2',
  'text-xs',
  'border-white',
  'h-full'
);

const lightCopy = classnames('!text-white');
const darkCopy = classnames('!text-nzxt-light-grey-800');
export const text = (isInverted: boolean): string =>
  classnames('font-medium', 'relative', 'text-center', {
    [lightCopy]: !isInverted,
    [darkCopy]: isInverted,
  });

export const link = (isInverted: boolean): string =>
  classnames('font-medium', 'text-xs', 'hover:underline', {
    [lightCopy]: !isInverted,
    [darkCopy]: isInverted,
  });

export const arrow = classnames(
  'h-3',
  'w-3',
  'text-white',
  'animate-textColorSwitch',
  'stroke-current'
);
export const sliderButton = classnames(
  'focus:outline-none',
  'absolute',
  'rounded-full',
  'flex',
  'items-center',
  'justify-center',
  'p-2'
);
