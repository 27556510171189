/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import parseLocale from '@utils/parse-locale';
import { useRouter } from 'next/router';
import type { NavLinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import useUiStore, {
  getOpenModal,
  getSetModalContent,
  getSetModalView,
} from '@stores/use-ui-store';
import * as styles from './styles';

type Props = {
  bannerCopy: string;
  internalLink?: NavLinkedItem;
  buttonLinkLabel?: string;
  isInverted?: boolean;
};

const MarketingBannerInner: FC<Props> = ({
  bannerCopy,
  internalLink,
  buttonLinkLabel,
  isInverted = false,
}) => {
  const { locale, defaultLocale } = useRouter();
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0] === 'en' ? 'en-us' : parsedLocale[0];

  const setModalView = useUiStore(getSetModalView);
  const setModalContent = useUiStore(getSetModalContent);
  const openModal = useUiStore(getOpenModal);

  const handleNoticeModal = (markdownContent, header): void => {
    setModalView('NOTICE_PAGE');
    setModalContent({ markdownCopy: header, markdownContent });
    openModal();
  };

  const openNoticeModal =
    internalLink &&
    internalLink._modelApiKey &&
    internalLink._modelApiKey === 'notice';

  const internal =
    !openNoticeModal && internalLink
      ? getCmsLinkUrl({
          contentType: internalLink._modelApiKey,
          pageType: internalLink.pageType,
          slug: internalLink.slug,
          parentSlug: internalLink?.parentCategory?.slug,
        })
      : null;

  return (
    <div className={styles.flexContainer}>
      <div className={styles.textWrapper}>
        <p
          className={styles.text(isInverted)}
          lang={lang}
          style={{ hyphens: 'auto' }}
        >
          {bannerCopy}{' '}
          {internal && (
            <ButtonLink
              className={styles.text(isInverted)}
              href={internal}
              internalLink
              aria-label={`${buttonLinkLabel} ${process.env.NEXT_PUBLIC_BASE_URL}${internal}`}
            >
              {buttonLinkLabel}
            </ButtonLink>
          )}
          {openNoticeModal && buttonLinkLabel && (
            <ButtonLink
              className={styles.text(isInverted)}
              onClick={() => {
                handleNoticeModal(
                  internalLink.noticeModalContent,
                  internalLink.heading || null
                );
              }}
            >
              {buttonLinkLabel}
            </ButtonLink>
          )}
        </p>
      </div>
    </div>
  );
};

export default memo(MarketingBannerInner);
