/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */

import { FC } from 'react';
import useCart from '@framework/cart/use-cart';
import {
  ExtendDecoratedShopifyCart,
  ShopifyLineEdges,
} from '@framework/api/cart';
import useUpdateItem from '@framework/cart/use-update-item';
import decodeVariantId from '@utils/decode-variant-id';

declare global {
  interface Window {
    Extend?: any;
    ExtendShopify?: any;
  }
}

interface WarrantyItem extends ShopifyLineEdges {
  properties?: Required<'IsExtendWarranty' | 'Term'>;
  key?: string;
}

export function sortByPrice(w1: WarrantyItem, w2: WarrantyItem): number {
  return (
    parseFloat(w1.node.merchandise.priceV2.amount) -
    parseFloat(w2.node.merchandise.priceV2.amount)
  );
}

function isWarranty(item: ShopifyLineEdges): boolean {
  // INT-641 - Stand alone warranties will have a Extend Token and will be treated as merchant products

  return Boolean(
    item.node.attributes &&
      item.node.attributes.find(att => att.key === 'Extend.IsExtendWarranty') &&
      !item.node.attributes.find(att => att.key === 'Extend.LeadToken')
  );
}

interface DenormalizedProductWithWarranties {
  quantity: number;
  warrantyQuantity: number;
  leadQuantity?: number;
  leadProductKey?: string;
  leadToken?: string;
  warranties: WarrantyItem[];
  buildNumber?: string;
  isWarranty?: boolean;
}

type Props = {
  items: WarrantyItem[];
};

const ExtendHelper: FC<Props> = ({ items }) => {
  // HOOKS
  const { data, isEmpty } = useCart();
  let currItem;

  // Empty Array to push products into
  const updates = getCartUpdates(data);
  const updateItem = useUpdateItem();

  for (const itemId in updates) {
    window.setTimeout(function () {
      // updated quantity for itemId
      const updatedQuantity = updates[itemId];
      const merchId = `gid://shopify/ProductVariant/${itemId}`;
      const itemToUpdate = items.find(i => i.node.merchandise.id === merchId);
      const updateId = itemToUpdate?.node?.id;
      const att = itemToUpdate?.node?.attributes;

      if (updateId) {
        updateItem({
          id: updateId,
          quantity: updatedQuantity,
          attributes: att,
        });
      }
    }, 1500);

    currItem = itemId;
  }

  // HELPER FUNCTIONS
  function getCartUpdates(
    data: ExtendDecoratedShopifyCart
  ): Record<string, number> | null {
    const updates: Record<string, number> = {};
    const products: Record<string, DenormalizedProductWithWarranties> = {};

    data?.lines.edges.sort(function (a, b) {
      const itemInCartA = a.node.merchandise.product.title;
      const itemInCartB = b.node.merchandise.product.title;
      if (
        itemInCartA !== 'Extend Protection Plan' &&
        itemInCartB === 'Extend Protection Plan'
      )
        return -1;
      return 0;
    });

    data?.lines.edges.forEach((item, index) => {
      if (
        !isWarranty(item) &&
        !item.node.attributes.find(att => att.key === 'Extend.LeadToken') &&
        item.node.attributes[0] &&
        item.node.merchandise.product.productType !== 'Cases' &&
        !item.node.attributes.find(att => att.key === '_hybrid_id') &&
        !item.node.attributes.find(att => att.key === 'added_with')
      )
        return;

      const shopify_variants_id = decodeVariantId(item.node.merchandise.id);

      // if its a warranty we try to match a product based on title or the shopify variantId
      let productVariantId =
        isWarranty(item) &&
        item.node.attributes.find(att => att.key === '_Extend.ProductId')
          ? item.node.attributes.find(att => att.key === '_Extend.ProductId')
              .value
          : shopify_variants_id;

      // Custom build warranty check
      // If item is a warranty and has a Ref attribute set the productVariantId to be the parent product variantId
      if (
        isWarranty(item) &&
        item.node.attributes.find(att => att.key === '_build_number')
      ) {
        productVariantId = item.node.attributes.find(
          att => att.key === '_build_number'
        ).value;
      }

      let product;

      // Creates schemas for each product type then calls insertProductData on completion
      function setProductType(callback) {
        // The item is a custom build computer product
        if (
          item.node.attributes[0] &&
          item.node.attributes[0].key === '_build_number'
        ) {
          product = products[item.node.attributes[0].value] || {
            quantity: 0,
            warrantyQuantity: 0,
            warranties: [],
            buildNumber: item.node.attributes[0].value,
          };
          // The item is a warranty for a custom build
        } else if (
          isWarranty(item) &&
          item.node.attributes.find(att => att.key === '_build_number')
        ) {
          product = products[productVariantId] || {
            quantity: 0,
            warrantyQuantity: 0,
            warranties: [],
            buildNumber: item.node.attributes.find(
              att => att.key === '_build_number'
            ).value,
            isWarranty: true,
          };
          // The item is a non-custom build NZXT product or a regular item warranty
        } else if (
          item.node.attributes.find(att => att.key === 'Extend.LeadToken') &&
          !isWarranty(item)
        ) {
          product = products[productVariantId] || {
            quantity: item.node.quantity,
            leadQuantity: parseInt(
              item.node.attributes.find(
                att => att.key === 'Extend.LeadQuantity'
              ).value,
              10
            ),
            leadProductKey: productVariantId,
            leadToken: item.node.attributes.find(
              att => att.key === 'Extend.LeadToken'
            ).value,
            warrantyQuantity: 0,
            warranties: [],
          };
        } else {
          product = products[productVariantId] || {
            quantity: 0,
            warrantyQuantity: 0,
            warranties: [],
          };
        }
        // Calls insertProductData after schemas are created
        callback();
      }

      // Inserts metadata into each product for normalization purposes
      function insertProductData() {
        // Item is an Extend warranty
        if (isWarranty(item)) {
          // Warranty is for a custom build pc
          if (
            item.node.attributes &&
            item.node.attributes.find(att => att.key === '_build_number')
          ) {
            // If there is no warranted product in the cart, remove the warranty
            if (!products[product.buildNumber]) {
              updates[shopify_variants_id] = 0;
              return;
            }
            // Set warranted product warrantyQuantity field
            products[product.buildNumber].warrantyQuantity +=
              item.node.quantity;
            // Set warranty quantity metadata
            product.quantity += item.node.quantity;
            product.warrantyQuantity += item.node.quantity;
            // Append warranties array for warranted product with current warranty
            products[product.buildNumber].warranties.push(item);

            // Warranty is for a non custom pc item
          } else {
            product.warrantyQuantity += item.node.quantity;
            product.warranties.push(item);
          }

          // Item is an NZXT product
        } else {
          if (
            item.node.attributes[0] &&
            item.node.merchandise.product.productType !== 'Cases' &&
            !item.node.attributes.find(att => att.key === '_hybrid_id') &&
            !item.node.attributes.find(att => att.key === 'added_with')
          ) {
            return;
          }
          product.quantity = item.node.quantity;
          // If warranty product is coming from lead, set leadProductKey and leadQuantity on product object,
          // so we can get them when iterating over products
          if (
            item.node.attributes &&
            item.node.attributes.findIndex(p => p.key === 'Extend.LeadQuantity')
          ) {
            item.node.attributes.forEach(attribute => {
              if (attribute.key === 'Extend.LeadQuantity') {
                product.leadQuantity = Number(attribute.value);
              }
            });

            product.leadProductKey =
              item.node.merchandise.id.split('/ProductVariant/')[1];
          }
        }
      }

      setProductType(insertProductData);

      // If its custom set using buildid else set using shopifyvarid
      if (
        item.node.attributes &&
        item.node.attributes[0] &&
        item.node.attributes[0].value &&
        item.node.merchandise.product.productType === 'Cases'
      ) {
        products[item.node.attributes[0].value] = product;
      } else if (
        item.node.attributes.find(att => att.key === 'Extend.LeadToken')
      ) {
        products[productVariantId] = product;
      } else {
        products[productVariantId] = product;
      }

      // Remove Extend Items without Properties from Cart - Unattached Warranties
      if (
        item.node.merchandise.title.indexOf('Extended Product Protection') ===
          -1 &&
        (!item.properties || !item.properties['IsExtendWarranty']) &&
        item.key
      ) {
        updates[shopify_variants_id] = 0;
      }
    });
    let customBuildNumber;
    const balance = false;
    const leadBalance = true;
    const leads = [];
    Object.keys(products)
      .map(key => products[key])
      .forEach(product => {
        // If product is a lead
        if (product.leadToken) {
          // Check if the leadExists in our leads array and if so, remove the current lead from cart for the new one
          const leadExists = leads.filter(
            lead => lead === product.leadToken.toString()
          );
          if (leadExists[0]) {
            updates[product.leadProductKey] = 0;
          } else {
            // If lead doesn't exist push to leads array
            leads.push(product.leadToken);
          }
        }
        // If warranty product is coming from lead, and product quantity is higher than lead quantity
        // (e.g. user manually increased product quantity after coming in from lead), then reset product quantity to lead quantity.
        if (
          product.leadQuantity &&
          product.leadProductKey &&
          (product.quantity > product.leadQuantity ||
            (leadBalance && product.quantity > product.leadQuantity))
        ) {
          updates[product.leadProductKey] = product.leadQuantity;
          return;
        }

        let quantityDiff = product.warrantyQuantity - product.quantity;

        const warranties = product.warranties.sort(sortByPrice);

        // not enough warranties
        if (quantityDiff < 0) {
          // only add warranties if options.balance = true
          if (balance && warranties.length) {
            const maxPriceWarranty = warranties[warranties.length - 1];
            const shopify_variants_id = decodeVariantId(
              maxPriceWarranty.node.merchandise.id
            );
            updates[shopify_variants_id] =
              maxPriceWarranty.node.quantity - quantityDiff;
          }
          return;
        }

        if (!balance && warranties.length) {
          // else, too many warranties
          warranties.forEach(warranty => {
            const shopify_variants_id = decodeVariantId(
              warranty.node.merchandise.id
            );

            // grab buildnumber
            if (warranty.node.attributes && warranty.node.attributes[1]) {
              const customBuildNumber = warranty.node.attributes[1].value;
            }

            // if buildnumber
            if (customBuildNumber) {
              if (quantityDiff === 0) return;

              // loop through all items in cart
              data.lines.edges.forEach(item => {
                // if item has buildnumber on it
                if (item.node.attributes[0]) {
                  if (item.node.merchandise.product.productType !== 'Cases') {
                    return;
                  }

                  if (item.node.attributes[0].value === customBuildNumber) {
                    // quantity diff is warranty quantity - item quantity
                    quantityDiff = warranty.node.quantity - item.node.quantity;

                    // start removing quantities until we reach our target
                    const newQuantityDiff = Math.max(
                      0,
                      quantityDiff - warranty.node.quantity
                    );
                    const removedQuantity = quantityDiff - newQuantityDiff;
                    updates[shopify_variants_id] =
                      warranty.node.quantity - removedQuantity;
                    quantityDiff = newQuantityDiff;
                  }
                }
              });
            } else {
              if (quantityDiff === 0) return;

              // ISSUE HERE
              const shopify_variants_id = decodeVariantId(
                warranty.node.merchandise.id
              );

              // start removing quantities until we reach our target
              const newQuantityDiff = Math.max(
                0,
                quantityDiff - warranty.node.quantity
              );
              const removedQuantity = quantityDiff - newQuantityDiff;

              // update directly instead of using updates
              window.setTimeout(function () {
                updateItem({
                  id: warranty.node.id,
                  quantity: warranty.node.quantity - removedQuantity,
                  attributes: warranty.node.attributes,
                });
              }, 1500);

              quantityDiff = newQuantityDiff;
            }
          });
        }
      });

    return Object.keys(updates).length ? updates : null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default ExtendHelper;
