import { FC, useRef, memo, useEffect, useState } from 'react';
import cn from 'clsx';
import debounce from 'lodash.debounce';
import Router from 'next/router';
import { useKeenSlider } from 'keen-slider/react';
import type { MarketingBannerProps } from '@framework/api/types';
import MarketingBannerInner from './MarketingBannerInner';
import 'keen-slider/keen-slider.min.css';

import * as styles from './styles';

type Props = {
  marketingBannerData: MarketingBannerProps[];
  setActiveSlide: (slide: number) => void;
  activeSlide?: string;
};

const ResizePlugin = (slider): void => {
  const observer = new ResizeObserver(() => {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const MarketingBannerCarousel: FC<Props> = ({
  marketingBannerData,
  setActiveSlide,
}) => {
  const timer = useRef(null);
  const [pause, setPause] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: 'free-snap',
      slides: { spacing: 15 },
      loop: true,
      created() {
        setIsMounted(true);
      },
      slideChanged(s) {
        setActiveSlide(s.track.details.rel || 0);
      },
    },
    [ResizePlugin]
  );

  const resetSlide = (): void => {
    if (instanceRef?.current) {
      instanceRef.current?.update();
      instanceRef.current?.moveToIdx(0);
    }
  };

  const debouncedUnfocus = debounce(resetSlide, 250);
  useEffect(() => {
    // eslint-disable-next-line
    timer.current = setInterval(() => {
      if (instanceRef?.current) {
        instanceRef?.current.next();
      }
    }, 8500);
    Router.events.on('routeChangeComplete', debouncedUnfocus);
    if (pause) {
      clearInterval(timer.current);
    }

    return () => {
      clearInterval(timer.current);
      Router.events.off('routeChangeComplete', debouncedUnfocus);
    };
  }, [instanceRef, pause, debouncedUnfocus]);

  return Array.isArray(marketingBannerData) &&
    marketingBannerData?.length > 0 ? (
    <div
      className={styles.flexContainerCarousel}
      onMouseEnter={() => setPause(!pause)}
      onMouseLeave={() => setPause(!pause)}
    >
      <div
        ref={sliderRef}
        className={cn('keen-slider', styles.carouselWrapper(isMounted))}
      >
        {marketingBannerData.map((bannerItem, i) => (
          <div
            key={bannerItem.id}
            className={`keen-slider__slide number-slide${i}`}
          >
            <MarketingBannerInner
              bannerCopy={bannerItem.bannerCopy}
              internalLink={bannerItem.internalLink}
              buttonLinkLabel={bannerItem.buttonLinkLabel}
              isInverted={bannerItem?.isInverted}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(MarketingBannerCarousel);
